export const seo = {
  url: 'przewoznik',
  title: {
    pl: 'Strefa Przewoźnika | Sieć Partnerów',
    en: `Carrier's Zone | Partner network`,
  },
  desc: {
    pl: 'Dołącz do sieci Partnerów Omida VLS - jednej z największej spedycji w Polsce. Realizujemy współpracę dla przewoźników w obszarze transportu krajowego oraz międzynarodowego.',
    en: 'Join the network of Omida VLS Partners - one of the largest forwarders in Poland. We offer cooperation for carriers in the field of domestic and international transport.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Strefa Przewoźnika',
    en: `Carrier's Zone`,
  },
  desc: {
    pl: `
    <span>
      <strong>Dane do faktury:</strong></br>
      </br>
      OMIDA VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK<br/>
      <br/>
      KRS: 0000459017<br/>
      NIP: 585 146 62 50 <br/>
    </span>
    `,
    en: `
    <span>
      <strong>Billing:</strong></br>
      </br>
      OMIDA VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK<br/>
      <br/>
      KRS: 0000459017<br/>
      NIP: 585 146 62 50 <br/>
    </span>
    `,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Learn more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Strefa Przewoźnika - Dołącz do sieci Partnerów Omida VLS',
    en: 'Carrier Zone - Join the network of Omida VLS Partners',
  },
  texts: [
    {
      pl: '<span>Dołącz do sieci Partnerów Omida VLS - jednej z największej spedycji w Polsce. Realizujemy <strong>współpracę dla przewoźników</strong> w obszarze transportu krajowego oraz międzynarodowego. Wypełnij poniższy formularz, podając dane Twojej firmy oraz określając potrzeby biznesowe. Specjaliści poinformują Państwa drogą mailową lub telefoniczną, przygotowując propozycję współpracy.</span>',
      en: '<span>Join the network of Omida VLS Partners - one of the largest forwarding companies in Poland. We offer <strong> cooperation for carriers </strong> in the field of domestic and international transport. Fill out the form below providing your company details and identifying your business needs. Specialists will inform you by e-mail or telephone while preparing a cooperation proposal. </span>',
    },
  ],
  features: [
    {
      icon: 'przewoznik1',
      subtitle: {
        pl: 'Certyfikat',
        en: 'Certificate',
      },
      title: {
        pl: 'CWB',
        en: 'CWB',
      },
      desc: {
        pl: 'Certyfikat Wiarygodności Biznesowej - Wiarygodny Płatnik',
        en: 'Certificate of Business Credibility - Credible Payer',
      },
    },
    {
      icon: 'przewoznik2',
      subtitle: {
        pl: 'Krótkie terminy',
        en: 'Fast',
      },
      title: {
        pl: 'Płatności',
        en: 'Payments',
      },
      desc: {
        pl: 'Krótkie terminy płatności na zasadzie skonta',
        en: 'Short payment terms on the basis of a discount',
      },
    },
    {
      icon: 'przewoznik6',
      subtitle: {
        pl: 'Systematyczne',
        en: 'Systematic',
      },
      title: {
        pl: 'Zlecenia',
        en: 'Orders',
      },
      desc: {
        pl: 'Gwarancja ciągłości zleceń i stabilnej współpracy',
        en: 'Guarantee of continuity of orders and stable cooperation',
      },
    },
    {
      icon: 'przewoznik3',
      subtitle: {
        pl: 'Kontakt ze spedytorem i',
        en: 'Forwarder',
      },
      title: {
        pl: 'Wsparcie',
        en: 'Support',
      },
      desc: {
        pl: 'Indywidualna opieka spedytora i działu floty',
        en: 'Individual care of the forwarder and the fleet department',
      },
    },
    {
      icon: 'przewoznik7',
      subtitle: {
        pl: 'Partnerskie',
        en: 'Partnership',
      },
      title: {
        pl: 'Relacje',
        en: 'Relations',
      },
      desc: {
        pl: 'Otwartość i równość współpracy',
        en: 'Openness and equality of cooperation',
      },
    },
    {
      icon: 'przewoznik4',
      subtitle: {
        pl: 'Całoroczna',
        en: 'Year-round',
      },
      title: {
        pl: 'Współpraca',
        en: 'Cooperation',
      },
      desc: {
        pl: 'Stała, całoroczna współpraca w kółkach',
        en: 'Permanent, year-round cooperation in circle routes',
      },
    },
    {
      icon: 'przewoznik8',
      subtitle: {
        pl: 'Duże kilometrowe',
        en: 'High',
      },
      title: {
        pl: 'Przebiegi',
        en: 'Mileage',
      },
      desc: {
        pl: 'Korzystne zlecenia transportowe',
        en: 'Profitable transport orders',
      },
    },
    {
      icon: 'przewoznik5',
      subtitle: {
        pl: 'Interesujące',
        en: 'Interesting',
      },
      title: {
        pl: 'Bonusy',
        en: 'Bonuses',
      },
      desc: {
        pl: 'Karty paliwowe, ubezpieczenie floty i wiele innych',
        en: 'Fuel cards, fleet insurance and much more',
      },
    },
  ],
}

// export const video = {
//   title: {
//     pl: 'Dołącz do projektu Fleet Carrier!',
//     en: 'Join the Fleet Carrier project!',
//   },
//   desc: {
//     pl: 'Poszukujemy przewoźników do stałej współpracy. Dlaczego warto współpracować z nami? <br/>O tym, co zyskasz, opowiedział Artur - pomysłodawca projektu Fleet Carrier.',
//     en: 'We are looking for carriers for permanent cooperation. Why is it worth cooperating with us? Arthur - the originator of the Fleet Carrier project told about what you will gain.',
//   },
//   url: 'https://www.youtube.com/watch?v=xE1Y_hzLW58',
//   more: [],
// }